import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isSendLoading: false,
  isSendSuccess: false,
  success: null,
  failed: null,
};

export default function getContacts(state = initialState, action) {
  switch (action.type) {
    case types.GET_CONTACTS_DETAIL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_CONTACTS_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload.docs,
        failed: null,
      };
    case types.GET_CONTACTS_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload,
      };
    case types.SEND_CONTACTS_DETAIL_LOADING:
      return {
        ...state,
        isSendLoading: true,
        isSendSuccess: false,
      };
    case types.SEND_CONTACTS_DETAIL_SUCCESS:
      return {
        ...state,
        isSendLoading: false,
        isSendSuccess: true,
      };
    case types.SEND_CONTACTS_DETAIL_FAIL:
      return {
        ...state,
        isSendLoading: false,
        isSendSuccess: false,
      };

    default:
      return state;
  }
}
