import 'firebase/auth';
import app from '../../Firebase';

import * as types from './types';

export const auth = (email, password) => (dispatch) => {
  dispatch({ type: types.AUTH_LOADING });

  app
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((res) => {
      dispatch({
        type: types.AUTH_SUCCESS,
        payload: res.user,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.AUTH_FAIL,
        payload: err,
      });
    });
};

export const currentUser = () => (dispatch) => {
  dispatch({ type: types.AUTH_LOADING });
  app.auth().onAuthStateChanged((user) => {
    if (user) {
      dispatch({
        type: types.AUTH_SUCCESS,
        payload: user,
      });
    } else {
      dispatch({
        type: types.AUTH_CURRENT_FAIL,
        payload: 'error',
      });
    }
  });
};

export const logout = () => (dispatch) => {
  dispatch({ type: types.LOGOUT_LOADING });

  app
    .auth()
    .signOut()
    .then(() => {
      dispatch({
        type: types.LOGOUT_SUCCESS,
        payload: true,
      });
    })
    .catch(() => {
      dispatch({
        type: types.LOGOUT_FAIL,
        payload: true,
      });
    });
};
