import * as types from '../actions/types';

const initialState = {
  isLoading: true,
  success: null,
  failed: null,
  failedCurrent: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case types.AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
        failed: null,
        failedCurrent: null,
      };
    case types.AUTH_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload,
        failedCurrent: null,
      };
    case types.AUTH_CURRENT_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: null,
        failedCurrent: action.payload,
      };
    default:
      return state;
  }
}
