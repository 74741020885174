import app from 'firebase/app';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_MAVRO_FIREBASE_CONFIG_APIKEY,
  authDomain: process.env.REACT_APP_MAVRO_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_MAVRO_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_MAVRO_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_MAVRO_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MAVRO_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_MAVRO_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_MAVRO_FIREBASE_MEASUREMENTID,
};

// Initialize Firebase
app.initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
var storage = app.storage();

// Create a storage reference from our storage service
var storageRef = storage.ref();

export { storage, storageRef, app as default };
