import React from 'react';

class Switcher extends React.Component {
  componentDidMount = () => {
    document.body.classList.remove('boxed');
    document.body.classList.remove('bg-repeat');
    document.body.id = '';
    document.body.style.backgroundImage = '';
  };

  render() {
    return (
      <>
        <link
          rel='stylesheet'
          type='text/css'
          href={'./assets/css/skin/skin-5.css'}
        />
      </>
    );
  }
}

export default Switcher;
