export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CURRENT_FAIL = 'AUTH_CURRENT_FAIL';

export const LOGOUT_LOADING = 'LOGOUT_LOADING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const GET_IMAGES_LOADING = 'GET_IMAGES_LOADING';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_FAIL = 'GET_IMAGES_FAIL';

export const GET_COMPANY_DETAIL_LOADING = 'GET_COMPANY_DETAIL_LOADING';
export const GET_COMPANY_DETAIL_SUCCESS = 'GET_COMPANY_DETAIL_SUCCESS';
export const GET_COMPANY_DETAIL_FAIL = 'GET_COMPANY_DETAIL_FAIL';

export const UPDATE_COMPANY_DETAIL_LOADING = 'UPDATE_COMPANY_DETAIL_LOADING';
export const UPDATE_COMPANY_DETAIL_SUCCESS = 'UPDATE_COMPANY_DETAIL_SUCCESS';
export const UPDATE_COMPANY_DETAIL_FAIL = 'UPDATE_COMPANY_DETAIL_FAIL';

export const GET_SLIDES_LOADING = 'GET_SLIDES_LOADING';
export const GET_SLIDES_SUCCESS = 'GET_SLIDES_SUCCESS';
export const GET_SLIDES_FAIL = 'GET_SLIDES_FAIL';

export const UPDATE_SLIDES_LOADING = 'UPDATE_SLIDES_LOADING';
export const UPDATE_SLIDES_SUCCESS = 'UPDATE_SLIDES_SUCCESS';
export const UPDATE_SLIDES_FAIL = 'UPDATE_SLIDES_FAIL';

export const GET_CONTACTS_DETAIL_LOADING = 'GET_CONTACTS_DETAIL_LOADING';
export const GET_CONTACTS_DETAIL_SUCCESS = 'GET_CONTACTS_DETAIL_SUCCESS';
export const GET_CONTACTS_DETAIL_FAIL = 'GET_CONTACTS_DETAIL_FAIL';

export const SEND_CONTACTS_DETAIL_LOADING = 'SEND_CONTACTS_DETAIL_LOADING';
export const SEND_CONTACTS_DETAIL_SUCCESS = 'SEND_CONTACTS_DETAIL_SUCCESS';
export const SEND_CONTACTS_DETAIL_FAIL = 'SEND_CONTACTS_DETAIL_FAIL';

export const GET_PROJECTS_LOADING = 'GET_PROJECTS_LOADING';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL';

export const ADD_NEW_PROJECT_LOADING = 'ADD_NEW_PROJECT_LOADING';
export const ADD_NEW_PROJECT_SUCCESS = 'ADD_NEW_PROJECT_SUCCESS';
export const ADD_NEW_PROJECT_FAIL = 'ADD_NEW_PROJECT_FAIL';

export const GET_PROJECT_IMAGES_LOADING = 'GET_PROJECT_IMAGES_LOADING';
export const GET_PROJECT_IMAGES_SUCCESS = 'GET_PROJECT_IMAGES_SUCCESS';
export const GET_PROJECT_IMAGES_FAIL = 'GET_PROJECT_IMAGES_FAIL';

export const GET_COVER_IMAGE_LOADING = 'GET_COVER_IMAGE_LOADING';
export const GET_COVER_IMAGE_SUCCESS = 'GET_COVER_IMAGE_SUCCESS';
export const GET_COVER_IMAGE_FAIL = 'GET_COVER_IMAGE_FAIL';

export const GET_PROJECT_DETAIL_LOADING = 'GET_PROJECT_DETAIL_LOADING';
export const GET_PROJECT_DETAIL_SUCCESS = 'GET_PROJECT_DETAIL_SUCCESS';
export const GET_PROJECT_DETAIL_FAIL = 'GET_PROJECT_DETAIL_FAIL';
