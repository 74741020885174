import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationTR from './tr/translation.json';
import translationEN from './en/translation.json';

const storageLanguage = localStorage.getItem('language');
const storageLanguageStatus =
  storageLanguage === 'tr' || storageLanguage === 'en';

const resources = {
  tr: {
    translation: translationTR,
  },
  en: {
    translation: translationEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: storageLanguageStatus ? storageLanguage : 'tr',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
