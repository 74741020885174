import * as types from '../actions/types';

const initialState = {
  isLoading: true,
  isUpdateLoading: false,
  success: null,
  failed: null,
  isImageLoading: true,
  imageSuccess: null,
  imageFail: null,
  isImageLoadingCover: false,
  imageSuccessCover: null,
  imageFailCover: null,
  isProjectDetailLoading: false,
  projectDetailSuccess: null,
  projectDetailFail: null,
};

export default function projects(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROJECTS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload.docs,
        failed: null,
      };
    case types.GET_PROJECTS_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload,
      };
    case types.GET_PROJECT_DETAIL_LOADING:
      return {
        ...state,
        isProjectDetailLoading: true,
      };
    case types.GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        isProjectDetailLoading: false,
        projectDetailSuccess: action.payload,
        projectDetailFail: null,
      };
    case types.GET_PROJECT_DETAIL_FAIL:
      return {
        ...state,
        isProjectDetailLoading: false,
        projectDetailSuccess: null,
        projectDetailFail: action.payload,
      };
    case types.GET_PROJECT_IMAGES_LOADING:
      return {
        ...state,
        isImageLoading: true,
        imageSuccess: null,
        imageFail: null,
      };
    case types.GET_PROJECT_IMAGES_SUCCESS:
      return {
        ...state,
        isImageLoading: false,
        imageSuccess: action.payload,
        imageFail: null,
      };
    case types.GET_PROJECT_IMAGES_FAIL:
      return {
        ...state,
        isImageLoading: false,
        imageSuccess: null,
        imageFail: action.payload,
      };
    case types.GET_COVER_IMAGE_LOADING:
      return {
        ...state,
        isImageLoadingCover: true,
        imageSuccessCover: null,
        imageFailCover: null,
      };
    case types.GET_COVER_IMAGE_SUCCESS:
      return {
        ...state,
        isImageLoadingCover: false,
        imageSuccessCover: action.payload,
        imageFailCover: null,
      };
    case types.GET_COVER_IMAGE_FAIL:
      return {
        ...state,
        isImageLoadingCover: false,
        imageSuccessCover: null,
        imageFailCover: action.payload,
      };

    default:
      return state;
  }
}
