import app from '../../Firebase';
import 'firebase/firestore';

// Import Antd
import { notification } from 'antd';

import * as types from './types';

export const getCompanyDetail = () => (dispatch) => {
  dispatch({ type: types.GET_COMPANY_DETAIL_LOADING });

  app
    .firestore()
    .collection('company')
    .get()
    .then((res) => {
      dispatch({
        type: types.GET_COMPANY_DETAIL_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_COMPANY_DETAIL_FAIL,
        payload: err,
      });
    });
};

export const updateCompanyDetail = (variables) => (dispatch) => {
  dispatch({ type: types.UPDATE_COMPANY_DETAIL_LOADING });

  app
    .firestore()
    .collection('company')
    .doc('154dXcwmMUFhcMCGHqdL')
    .set(variables)
    .then(() => {
      notification.success({
        message: 'İşlem Başarılı!',
        description: 'Güncelleme gerçekleşti.',
        placement: 'bottomLeft',
      });
      dispatch({
        type: types.UPDATE_COMPANY_DETAIL_SUCCESS,
      });
      dispatch(getCompanyDetail());
    })
    .catch(() => {
      notification.error({
        message: 'Hata!',
        description: 'İşlem gerçekleştirilemedi.',
        placement: 'bottomLeft',
      });
      dispatch({
        type: types.UPDATE_COMPANY_DETAIL_FAIL,
      });
    });
};
