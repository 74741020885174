import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Layout, Menu, Button } from 'antd';
import {
  DesktopOutlined,
  InfoCircleOutlined,
  BankOutlined,
  PartitionOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import MavroLogo from '../../images/mavro-logo.png';

const { Header, Content, Sider } = Layout;

function DefaultLayout(props) {
  const { children } = props;

  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed((prevState) => !prevState);
  };

  const handleWindowSize = () => {
    const width = window.innerWidth;
    if (width < 756) {
      setCollapsed(true);
    }
  };

  useEffect(() => {
    handleWindowSize();
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
        <NavLink to={'./'}>
          <div className='dashboard-logo'>
            <img src={MavroLogo} alt='Mavro' width='100%' />
          </div>
        </NavLink>
        <Menu
          theme='dark'
          defaultSelectedKeys={[useLocation()?.pathname]}
          mode='inline'
        >
          <Menu.Item key='/yonetim' icon={<InfoCircleOutlined />}>
            <NavLink to={'./yonetim'}>Şirket Bilgileri</NavLink>
          </Menu.Item>

          <Menu.Item key='/slayt' icon={<DesktopOutlined />}>
            <NavLink to={'./slayt'}>Slayt</NavLink>
          </Menu.Item>

          <Menu.Item key='/proje-yonetim' icon={<BankOutlined />}>
            <NavLink to={'./proje-yonetim'}>Projeler</NavLink>
          </Menu.Item>

          <Menu.Item key='/referanslar' icon={<PartitionOutlined />}>
            <NavLink to={'./referanslar'}>Referanslar</NavLink>
          </Menu.Item>

          <Menu.Item key='/iletisim-talepleri' icon={<MessageOutlined />}>
            <NavLink to={'./iletisim-talepleri'}>İletişim Talepleri</NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className='site-layout'>
        <Header
          className='site-layout-background'
          style={{
            padding: 0,
          }}
        >
          <Menu
            theme='dark'
            mode='horizontal'
            style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
          >
            <Menu.Item key='1'>
              <NavLink to={'./cikis'}>
                <Button ghost>Çıkış yap</Button>
              </NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ margin: '20px 16px' }}>{children}</Content>
      </Layout>
    </Layout>
  );
}

export default DefaultLayout;
