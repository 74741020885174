import React from 'react';
import { translate } from '../../common/utils/translateUtil';

const Loader = () => {
  return (
    <div className='loading-area'>
      <div className='loading-box' />
      <div className='loading-pic'>
        <div className='cssload-loader'>
          {translate('PUBLIC.LOADER.LOADING')}
        </div>
      </div>
    </div>
  );
};

export default Loader;
