// Import React
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Import Components
import PrivateRoute from './Elements/PrivateRoute';
import RouteChangeScroll from './Elements/RouteChangeScroll';

const Home2 = React.lazy(() => import('./Pages/Home2'));
const About = React.lazy(() => import('./Pages/About'));
const Services = React.lazy(() => import('./Pages/Services'));
const Error = React.lazy(() => import('./Pages/Error'));
const ContactUs = React.lazy(() => import('./Pages/ContactUs'));
const ProjectDetail = React.lazy(() => import('./Pages/ProjectDetail'));
const ProjectClassic1 = React.lazy(() => import('./Pages/ProjectClassic1'));
const References = React.lazy(() => import('./Pages/References'));
const LoginPage = React.lazy(() => import('./Pages/LoginPage'));
const Dashboard = React.lazy(() => import('./Pages/Dashboard'));
const Logout = React.lazy(() => import('./Pages/Logout'));
const ReferansYonetim = React.lazy(() => import('./Pages/ReferansYonetim'));
const Slider = React.lazy(() => import('./Pages/Slider'));
const ContactInfo = React.lazy(() => import('./Pages/ContactInfo'));
const ProjectsAdmin = React.lazy(() => import('./Pages/ProjectsAdmin'));




function Components() {
  return (
    <BrowserRouter basename='/'>
      <div className='page-wraper'>
        <RouteChangeScroll />
        <Switch>
          <Route path='/' exact component={Home2} />

          <Route path='/about' exact component={About} />
          <Route path='/services' exact component={Services} />

          <Route path='/contactus' exact component={ContactUs} />

          <Route path='/references' exact component={References} />

          <Route path='/projects' exact component={ProjectClassic1} />
          <Route path='/project-detail/:id' exact component={ProjectDetail} />

          <Route path='/panel' exact component={LoginPage} />
          <Route path='/cikis' exact component={Logout} />

          <PrivateRoute path='/yonetim' exact component={Dashboard} />
          <PrivateRoute path='/referanslar' exact component={ReferansYonetim} />
          <PrivateRoute path='/slayt' exact component={Slider} />
          <PrivateRoute path='/iletisim-talepleri' exact component={ContactInfo} />
          <PrivateRoute path='/proje-yonetim' exact component={ProjectsAdmin} />
          

          <Route component={Error} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default Components;
