import React from 'react';

const ScrolToTop = () => {
  return (
    <button className='scroltop'>
      <span className='fa fa-angle-up  relative' id='btn-vibrate' />
    </button>
  );
};

export default ScrolToTop;
