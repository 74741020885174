import auth from './auth';
import logout from './logout';
import getReferences from './references';
import getCompanyDetail from './company';
import getSlides from './slides';
import getContacts from './contacts';
import projects from './projects';

const reducers = {
  auth,
  logout,
  getReferences,
  getCompanyDetail,
  getSlides,
  getContacts,
  projects,
};

export default reducers;
