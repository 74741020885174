/* eslint-disable react-hooks/exhaustive-deps */
// Import React
import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Import Components
import Components from './components/Components';
import ScrolToTop from './components/Elements/ScrolToTop';
import Loader from './components/Elements/Loader';
import Switcher from './components/Elements/Switcher';

// Import Store
import { getCompanyDetail } from './store/actions/company';

// Import antd
import { ConfigProvider } from 'antd';

// Import i18n
import i18n from './common/locales/i18n';

// Import Assets
import 'antd/dist/antd.css';

import 'moment/locale/tr';
import trTR from 'antd/lib/locale/tr_TR';
import enUs from 'antd/lib/locale/en_US';

const App = () => {
  const dispatch = useDispatch();
  const companyDetailState = useSelector((state) => state?.getCompanyDetail);

  useEffect(() => {
    if (!companyDetailState?.success) {
      dispatch(getCompanyDetail());
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <ConfigProvider locale={i18n?.language === 'tr' ? trTR : enUs}>
        <div className='App'>
          <Components />
          <ScrolToTop />
          <Loader />
          <Switcher />
        </div>
      </ConfigProvider>
    </Suspense>
  );
};

export default withTranslation()(App);
