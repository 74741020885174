import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { currentUser } from '../../store/actions/auth';
import DefaultLayout from './DefaultLayout';

function PrivateRoute(props) {
  const { component: Component, ...rest } = props;

  const dispatch = useDispatch();

  const authUserState = useSelector((state) => state?.auth);

  useEffect(() => {
    if (!authUserState?.success) {
      dispatch(currentUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authUserState?.isLoading) {
    return <Loader />;
  }

  return (
    <Route
      {...rest}
      exact
      render={(props) => {
        if (authUserState?.failed) {
          return <Redirect to={'./panel'} />;
        }
        return (
          <DefaultLayout>
            <Component {...props} />
          </DefaultLayout>
        );
      }}
    />
  );
}

export default PrivateRoute;
